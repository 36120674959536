@import "common/variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower

@import "common/mixin";
@import "common/global";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/wp-classes";
@import "components/others";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/templatera";
@import "layouts/posts";
@import "layouts/tinymce";
@import "layouts/responsive";
@import "layouts/languages";

// Modificados por domingo@monema.com

// Para la fecha de los comentarios
.single-post time { font-size: 14px; }
.single-post .entry-content { font-size: 16px; color: #000000; line-height: 2em; }


// Esto es para el Knowledge base
.single-epkb_post_type_1                                                    { margin-top: 0px; }
.single-epkb_post_type_1 div.container div.content                          { margin-top: 85px; }
.single-epkb_post_type_1 h1.entry-title                                     { font-size: 37px; font-weight: 900; text-transform: uppercase; margin: 30px 0 0; }
.single-epkb_post_type_1 time                                               { font-size: 17px; color: $custom-color-6; text-transform: uppercase; margin: 0 0 10px 0; display: inline; }
.single-epkb_post_type_1 .author                                            { font-size: 17px; color: $custom-grey-1; display: inline; }
.single-epkb_post_type_1 .author a                                          { color: $custom-grey-1; }
.single-epkb_post_type_1 .avatar                                            { margin-right: 5px; 
                                                                  -webkit-border-radius: 32px;
                                                                  -moz-border-radius: 32px;
                                                                  border-radius: 32px;}
.single-epkb_post_type_1 .entry-content                                     { font-size: 18px; font-weight: 300; color: $custom-grey-1; margin: 25px 0 0; }
.single-epkb_post_type_1 .image img                                         { width: 100%; height: auto; }
.single-epkb_post_type_1 .ssba-wrap                                         { border-top: 1px solid $bkg-grey-1-border; padding-top: 25px; }
.single-epkb_post_type_1 .bkg-single-post                                   { height: 550px; }
.bkg-single-post .inner                                         { width: 100%; height: 100%; opacity: 0.3; }
.bkg-single-post .content                                       { max-width: 600px; padding: 0 30px; position: absolute; top: 50%; left: 50%; text-align: center; width: 100%; }
.bkg-single-post .content                                       { -webkit-transform: translate(-50%, 30%);
                                                                  -ms-transform: translate(-50%, 30%);
                                                                  -o-transform: translate(-50%, 30%);
                                                                  transform: translate(-50%, 30%); 
                                                                }
.single-epkb_post_type_1 .bkg-single-post .content                          { -webkit-transform: translate(-50%, -40%);
                                                                  -ms-transform: translate(-50%, -40%);
                                                                  -o-transform: translate(-50%, -40%);
                                                                  transform: translate(-50%, -40%); 
                                                                }

.single-epkb_post_type_1 .rp4wp-related-posts                                   { border-top: 1px solid $bkg-grey-1-border; padding-top: 45px; margin: 45px 0; overflow: hidden; }
.single-epkb_post_type_1 .rp4wp-related-posts h3                                { color: $custom-color-1; font-size: 18px; font-weight: 900; margin-top: 0; padding: 0 0 35px 40px; background: url(../svgs/icon-last-news.svg) left top 0px no-repeat; background-size: 30px; }
.single-epkb_post_type_1 .rp4wp-related-posts ul                                { margin: 0; padding: 0; list-style: none; }
.single-epkb_post_type_1 .rp4wp-related-posts li                                { width: 33%; float: left; padding-right: 20px; }
.single-epkb_post_type_1 .rp4wp-related-posts .rp4wp-related-post-image         { margin-bottom: 10px; }
.single-epkb_post_type_1 .rp4wp-related-posts .rp4wp-related-post-image img     { width: 100%; height: auto; }
.single-epkb_post_type_1 .rp4wp-related-posts .rp4wp-related-post-content a     { font-size: 16px; font-weight: 900; text-transform: uppercase; margin: 0; line-height: 1.1; display: block; }
.single-epkb_post_type_1 .rp4wp-related-posts .rp4wp-related-post-content p     { font-size: 14px; font-weight: 300; color: $custom-grey-1; margin: 10px 0 0; }

// Quitamos la cabecera en la ayuda
.single-epkb_post_type_1 .ssba {display: none;}
// Y los tags de abajo
#eckb-article-footer {display: none;}

.single-epkb_post_type_1 #post-header { display: block !important;}

.epkb-category-level-1 {text-transform: uppercase;}

@media (min-width: 1200px) and (max-width: 1370px) {
}
@media (min-width: 992px) {
  .single-epkb_post_type_1 div.container div.content .main                         { margin-top: 50px; }
}
@media (min-width: 992px) and (max-width: 1199px) {
}
@media (max-width: 991px) {
  .single-epkb_post_type_1 .bkg-single-post                                       { height: 430px; }
}
@media (max-width: 767px) {
  .single-epkb_post_type_1 .rp4wp-related-posts li                                { width: 100%; float: none; padding: 0 0 40px 0; }
}

.single-epkb_post_type_1 .main header {display: none}
.eckb-tag-container {display: none}

footer #eckb-article-content-header {display: none}
footer #eckb-article-page-container-v2 {display: none}

// Iconos de Google Play y App Store
.plaforms-col{
    text-align: center;
}

// Botón propio de click2call
.c2cbutton{
    font-size: 14px !important;
    padding: 5px !important;
    margin-left: 5px !important;
    background-color: #5899cf !important;
    border-color: #5899cf !important;
}

.c2cbutton:hover{
    background-color: #85c8ff !important;
}


.Click2CallVOIP {
    padding-left: 16px;
    margin-top: -4px;
}

// LiveHelper
footer #voipsysc2c_1 {
    float: right;
}

// Checkbox en el formulario de contacto
.wpcf7-list-item.first.last
{
    margin-left: 0px;
}

// Tabla de precios
#tablaprecios tr:nth-child(odd) td{
    background-color: #FBFBFB;
}

#tablaprecios th{
    color: #FF8501;
}

#tablaprecios
{
    border-collapse:separate;
    border-spacing:0 12px;
    margin: 0 auto;
    width: 100%;
}

#tablaprecios a
{
    color: #FF8501;
}

#tablaprecios tr td
{
    text-align: left;
}


// Tabla de planes
#tablaplanes td{
    padding-left: 6px;
    background-color: #fffffa;
    // Para el brochure    
    //background-color: #404f63;
    font-weight: 700;
    color: #a39161;
    // Para el brochure    
    //color: #dddddd;
    text-align: left;
}

#tablaplanes th{
    color: #FF8501;
}

#tablaplanes
{    
    border-spacing:0 12px;
    width: 100%;
}

#tablaplanes a:not(.vc_btn3)
{
    color: #FF8501;
}

#tablaplanes tr td
{
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
}


#tablaplanes tr:nth-child(even) td:nth-child(1)
{    
    background-color: #e9e9c8;
    // Para el brochure
    //background-color: #2e3f54;
}

#tablaplanes tr:nth-child(even) td:nth-child(2)
{
    background-color: #dff0fd;
    // Para el brochure
    //background-color: #2e3f54;
}

#tablaplanes tr:nth-child(even) td:nth-child(3)
{
    background-color: #dcfbe0;
    // Para el brochure
    //background-color: #2e3f54;
}

#tablaplanes tr:nth-child(even) td:nth-child(4)
{
    background-color: #ffe7e7;
    // Para el brochure
    //background-color: #2e3f54;
}

#tablaplanes thead tr th:nth-child(1)
{
 background-color: #e9e9c8;
 // Para el brochure
 //background-color: #2e3f54;
}

#tablaplanes thead tr th:nth-child(2)
{
 background-color: #dff0fd;
 // Para el brochure
 //background-color: #2e3f54;
}

#tablaplanes thead tr th:nth-child(3)
{
 background-color: #dcfbe0;
 // Para el brochure
 //background-color: #2e3f54;
}

#tablaplanes thead tr th:nth-child(4)
{
 background-color: #ffe7e7; 
 // Para el brochure
 //background-color: #2e3f54;
}

#tablaplanes .iconcheck
{
    color: #5dcb42;
}

#tablaplanes .iconunchecked
{
    color: #ff8a8b;
}

#tablaplanes tr:last-child td:nth-child(1)
{
 text-align: center !important;
 background-color: #e9e9c8 !important;
}

#tablaplanes tr:last-child td:nth-child(2)
{
 text-align: center !important;
 background-color: #dff0fd !important;
}

#tablaplanes tr:last-child td:nth-child(3)
{
 text-align: center !important;
 background-color: #dcfbe0 !important;
}

#tablaplanes tr:last-child td:nth-child(4)
{
 text-align: center !important;
 background-color: #ffe7e7 !important; 
}

// Carousel de inicio

.home #home-carousel .item {
	top:180px;
}

.home #home-carousel .item .icon-list li
{
	display: block;
}

/* Al final hemos quitado el carousel por temas de SEO */
.home .carousel-indicators
{
    display: none;
}

.ssba .ssba-img
{
        margin-right: 16px !important;
	height: 35px;
}

.wpcf7 input[type="text"],
.wpcf7 input[type="email"],
.wpcf7 textarea
{
	width: 100%;
}

@media (max-width: 767px)
{
		.home #service {
    	padding: 0 30px;
			background: #2e3f54;
		}
}

#home-carousel-slider-mobile
{
	margin-top: 40px;
}

#business-not-home                         { text-align: center;}
#business-not-home .wpb_single_image,
#business-not-home .wpb_text_column        { margin: 0; }
#business-not-home h2                      { margin: 0 0 20px 0; }
#business-not-home h2 strong               { font-weight: 900; }
#business-not-home p                       { font-size: 18px; margin: 0  0 20px 0; }
#business-not-home .business-icons         {}
#business-not-home .business-icons img     { width: 200px; height: 200px; }
#business-not-home .business-icons p       { font-size: 26px; line-height: 1.1; font-weight: 300; }

#quotes {
    padding: 35px 0 90px;
    background: #fbfbfb;
    border-bottom: 1px solid #eff0f1;
}

#local-business {
    padding: 35px 0 90px;
    background: #fbfbfb;
    border-bottom: 1px solid #eff0f1;
}

#ekomi {
    padding: 90px 0 35px;
    background: #fbfbfb;
}

.quote p {
    min-height: 60px;
}

#customers {
    z-index: 2;
    background: url(../images/bkg-column-right-1.webp) right top no-repeat;
}

@media (max-width: 767px)
{
#service {
    padding: 0 30px;
    background: #2e3f54;
}
}

.home #home-carousel .item:nth-child(1) {
    padding-left: 50%;
    padding-right: 5%;
}

@media (max-width: 767px)
{
.home #home-carousel-slider-mobile {
   padding-bottom: 50px;
}
}

#prueba-gratis-form-block
{
	padding-top: 50px;
}

#distribuidores-form-block
{
    padding-top: 50px;
}

#features-form-block
{
    padding-top: 175px;    
}

@media (max-width: 767px)
{
    #features-form-block
    {
        padding: 0;
    }
    #features-form-block h2
    {
        margin: 10px;
    }
    #features-form-block p
    {
        margin: 0 10px 0 10px;
    }
}

.distribuidores-parrafo-intro
{
    font-size: 16px;
    line-height: 1.5em;
}

.distribuidores-parrafo-intro h2
{
    padding-left: 15px;
}

.distribuidores-parrafo-intro li
{
    padding-left: 0px !important;
    margin-left: 30px;
}

.service-1 p,
.service-1 form label
{
	font-size: 16px;
	font-weight: 400;
}

.service-1 form p label
{
	display: inline !important;

}

div.wpcf7-mail-sent-ok {
    border: 2px solid #ffba7e;
    background-color: #ffba7e;
}

div.wpcf7-spam-blocked {
    border: 2px solid #ea1414;
    color: white;
    background-color: #ea1414;
}

div.wpcf7-validation-errors {
	  border: 2px solid #ea1414;
    color: white;
    background-color: #ea1414;
}

.menus {
    margin-top: 32px;
}

p a {
    color: #4986D7;
}

.centralita-section a {
    background: url(../svgs/icon-more.svg) left top 4px no-repeat;
    background-size: 10px 10px;
    padding-left: 15px;
}

.table-all-in a {
    color: #4986D7;   
    background: url(../svgs/icon-more.svg) right top 4px no-repeat;
    background-size: 10px 10px;
    padding-right: 15px;
}

.features-tour-icons a {
    color: #4986D7;   
    background: url(../svgs/icon-more.svg) left top 4px no-repeat;
    background-size: 10px 10px;
    padding-left: 15px;
}

.three-features-column a {
    color: #4986D7;
    background: url(../svgs/icon-more.svg) left top 4px no-repeat;
    background-size: 15px 15px;
    padding-left: 15px;
}

// Imágenes responsive en los artículos

.single-post .entry-content img {
    width: 100%;
    height: auto;
}

// El índice de contenidos de los artículos sale muy junto y Google decía que había enlaces muy juntos

#toc_container a {
    line-height: 2em;
}

.content-footer #toc_container {
    display: none;
}

// Problemas con texto demasiado pequeño para móviles en Google Search Console

#toc_container {
    font-size: 100%;
}

// Botón más grande
.bigger-button-price a
{
    font-size: 32px !important;
}

// Botón de probar gratis más grande en portada
.button-probar-home
{
    margin-top: 32px;
    text-align: center;
}
.button-probar-home a
{
    font-size: 24px !important;
}

.tryit .icon-list
{
    margin-top: 20px;
}

.column-business-telephony
{
    margin-left: 30px;
    margin-right: 30px;
}

// Formulario blog

.formulario-blog
{    
    float: right !important;    
    min-height: 384px !important;
}

.formulario-blog .service-1
{
    margin: 30px 0px 30px 0px;
    top: 0px;
}

.formulario-blog input
{
    margin-bottom: 12px;
}

.formulario-blog-texto
{
    color: white;
}

.get-service-blog{
    background: #6b5649;
    color: #fff;
    margin: 0;
    padding-left: 0.7%;
    padding-right: 1.4%;
    margin-left: 0px !important;
    margin-right: 0px !important;
    min-height: 444px !important;
}

.get-service-blog .wpb_single_image{
    margin-bottom: 0px !important;
    margin-top: 20px !important;
}

.single-post .main {
    width: 100% !important;
}

.single-post .main figure img {
    max-width: 60%;    
}

.single-post .main figure {
    text-align: center;
    margin: 48px 0px 48px 0px;    
}

.service-1-blog
{
    position: relative;
    color: black;
    padding: 8px 50px 8px 50px !important;
    margin: 4px;
    background: #f68e32;
}

.service-1-blog h2
{
    font-size: 24px;
    text-transform: uppercase;    
    margin-top: 8px !important;
    font-weight: bold;
}

.formulario-blog-texto
{
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5em;
}

.service-1-blog .wpcf7-form,
.service-1-blog .wpcf7-form label {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5em;
}

.service-1-blog .wpb_content_element
{
    margin-bottom: 12px !important
}

.single-post .bkg-single-post
{
    height: 450px !important;
}

@media (max-width: 767px) {
    .formulario-blog-image{ display: none; }
}
.formulario-blog-image img { 
    max-width: 100% !important;
}
