// Try it free
.tryit_free .wpb_text_column            { margin: 0 0 20px 0; }
.tryit_free h2                          { font-size: 35px; }     
.tryit_free h2 strong                   { font-size: 45px; font-weight: 900; }
.tryit_free p                           { font-size: 25px; }

// EKOMI stars
.ekomi_stars .logo                      { padding-right: 15px; margin-bottom: 0; }
.ekomi_stars .wpb_text_column,
.ekomi_stars .wpb_wrapper               { display: inline; margin: 0; }
.ekomi_stars p                          { font-size: 13px; padding-left: 15px; margin: 5px 0 0; }
.ekomi_stars p strong                   { font-size: 22px; }
.ekomi_stars .stars                     { padding-left: 15px; margin: 5px 0 0; }
.ekomi_stars span.star                  { color: $custom-color-6; }
.ekomi_stars span.star img              { width: 30px; height: 30px; }

.widget_np_templatera_widget .ekomi_stars .image    { width: 40%; }
.widget_np_templatera_widget .ekomi_stars .score    { width: 60%; }
.widget_np_templatera_widget .ekomi_stars .score > .vc_column-inner         { padding-left: 0; }
.widget_np_templatera_widget .ekomi_stars .wpb_single_image.vc_align_right  { text-align: center; }
.widget_np_templatera_widget .ekomi_stars p                                 { padding-left: 0px; }
.widget_np_templatera_widget .ekomi_stars .stars                            { padding-left: 0px; }

// El sello de valoración le ponemos el tamaño que no nos da ekomi porque google lo valora positivamente
.sidebar .vc_row .ekomi-rating-seal img {width: 90px; height: 90px;}
.ekomi-rating-seal img {width: 105px; height: 105px;}

// EKOMI Quotes
.ekomi_quotes .quotes                   { overflow: hidden; }
.ekomi_quotes .quote                    { width: 50%; float: left; }
.ekomi_quotes .quote-1                  { padding-right: 30px; }
.ekomi_quotes .quote-2                  { padding-left: 30px; }
.ekomi_quotes blockquote                { font-size: 30px; font-style: italic; font-weight: 300; background: url(../svgs/icon-monema.svg) left top no-repeat; background-size: 58px 58px; margin: 0; padding: 0 0 0 80px; border: 0; }
.ekomi_quotes.lazymonema blockquote                { font-size: 30px; font-style: italic; font-weight: 300; background: none; background-size: 58px 58px; margin: 0; padding: 0 0 0 80px; border: 0; }

.widget_np_templatera_widget .ekomi_quotes .quote                       { width: 100%; float: none; margin-bottom: 30px; }
.widget_np_templatera_widget .ekomi_quotes .quote-1                     { padding-right: 0px; }
.widget_np_templatera_widget .ekomi_quotes .quote-2                     { padding-left: 0px; }
.widget_np_templatera_widget .ekomi_quotes blockquote                   { font-size: 18px; background-size: 35px auto; padding-left: 50px; }
.widget_np_templatera_widget .ekomi_quotes .quotes .quote:last-child    { margin-bottom: 10px; }

// Get service // no está en tempaltera
.get-service                            { background: $custom-color-1; color: $custom-white; margin: 0; }
.get-service .col-left                  { text-align: right; padding-top: 50px; padding-bottom: 50px; }
.get-service .col-left .wpb_text_column { margin: 0; }
.get-service .col-left h2               { text-transform: uppercase; font-size: 30px; padding: 0 40px 0 100px; margin: 0 0 20px 0; }
.get-service .col-left p                { font-size: 16px; padding: 0 40px 0 100px; margin: 0 0 25px 0; }
.get-service .col-left img              { margin-top: 70px; }
.get-service .col-left .vc_btn3-container   { padding: 0 40px 0 0; }

.get-service.service-2                  { background: none; }
.get-service.service-2 .col-left        { text-align: left; }
.get-service.service-2 .col-left h2,
.get-service.service-2 .col-left p      { padding-left: 0; padding-right: 20%; }
.get-service.service-2 .col-left img    { margin-top: 20px; }

.service-1                              { position: relative; top: -40px; color: $custom-color-1; padding: 55px 35px 45px; margin: 0 30px 0; background: $custom-color-6 url(../images/bkg-widgets-2.webp) left bottom no-repeat; }
.no-bkg .service-1                      { background: none; color: #fff; margin-top: 40px; padding-bottom: 25px; }
.no-bkg .service-1 a                    { color: #fff; }
.service-1 p,
.service-1 p:last-child                 { font-size: 20px; margin: 0 0 20px; }
.service-1 .wpb_text_column             { margin: 0; }
.service-1 .deco                        { position: absolute; top: -227px; right: 10%; }
.no-bkg .service-1 .deco                { display: none; }
.service-1 form label                   { display: none; }
.service-1 form .form-control           { padding: 15px 10px; height: 45px; border-color: $custom-grey-1; -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px; }
.service-1 form .checkbox label         { display: block; }
.service-1 form .button                 { text-align: right; }
.service-1 form .wpcf7-not-valid-tip    { color: $custom-white; font-style: italic; margin-top: 1px; }
.service-1 form .wpcf7-not-valid-tip::before    { position: relative; top: -3px; margin: 0 5px 0 0; background-color: #cc0000; content: ''; display: inline-block; width: 5px; height: 5px; -moz-border-radius: 15px; -webkit-border-radius: 15px; border-radius: 15px; }
.service-1 div.wpcf7 img.ajax-loader    { display: block; text-align: right; float: right; margin-top: 15px; }
.service-1 div.wpcf7 img.ajax-loader    { width: 0px; height: 0px; padding: 8px; background: url(../images/ajax-loader-2.gif) center center no-repeat; }

.customers_logos                        { padding-right: 100px; }
.customers_logos .carousel-control      { display: none; }
.customers_logos .carousel-indicators   { display: none; }

.integrations                           { padding-top: 70px; padding-bottom: 0px; }
.integrations h3                        { display: inline-block; }
.integrations .wpb_single_image.vc_align_left { display: inline-block; padding: 0 10px; }


// #######################################################################
// MEDIA QUERYS
// #######################################################################
/* LG */
@media (min-width: 1200px) and (max-width: 1370px) {
    .service-1                          { margin: 0 20px; }
    .service-1 .deco                    { right: 3%; }
    .get-service .col-left h2,
    .get-service .col-left p            { padding-left: 40px; }
    .customers_logos .vc_col-lg-6       { width: 100%; }
    .customers_logos                    { padding-right: 140px; }
}
@media (min-width: 992px) {
}
/* MD */
@media (min-width: 992px) and (max-width: 1199px) {
    .service-1 .deco                        { right: 3%; }
    .customers_logos                        { padding-right: 180px; }
}
/* SM - Tablet */
@media (max-width: 991px) {
    .service-1                              { margin: 0 20px 0 10px; }
    .service-1 .deco                        { display: none; }
    .get-service .col-left h2,
    .get-service .col-left p                { padding-left: 0px; }
    .get-service .col-left                  { padding-left: 15px; }

    .ekomi_quotes .quote                    { width: 100%; float: none; margin-bottom: 45px; }
    .ekomi_quotes .quote-1                  { padding-right: 0px; }
    .ekomi_quotes .quote-2                  { padding-left: 0px; }

    .customers_logos img                    { width: 100%; height: auto; }
}
/* XS */
@media (max-width: 767px) {
    .tryit_free .wpb_column                     { padding: 0 35px; } 
    .get-service .col-left                      { padding-bottom: 20px; padding-top: 20px; }
    .get-service .col-left .wpb_single_image    { padding-right: 40px; margin-bottom: 0; }
    .get-service .col-left img                  { margin-top: 20px; }
    .get-service .col-right                     { margin-top: 30px; }

    .ekomi_stars .logo                          { padding-right: 0px; text-align: center; }
    .ekomi_stars p                              { padding-left: 0px; text-align: center; }
    .ekomi_stars .stars                         { padding-left: 0px; text-align: center; }

    .widget_np_templatera_widget .ekomi_stars .image    { width: 35%; }
    /*.widget_np_templatera_widget .ekomi_stars .image img{ padding-top: 15px; }  // Lo quitamos porque al poner el tamaño fijo del sello arriba nos distorsionaba la imagen */
    .widget_np_templatera_widget .ekomi_stars .score    { width: 65%; }
    .widget_np_templatera_widget .ekomi_stars p         { text-align: left; }

    .ekomi_quotes .quotes                                   { padding: 0 30px; }
    .widget_np_templatera_widget .ekomi_quotes .quotes      { padding: 0; }

    .customers_logos                            { padding-right: 0px; margin-bottom: 50px; margin-left: 0px; margin-right: 0px; }
    .customers_logos img                        { width: auto; height: auto; }
}
