footer .content-footer                          { background: $custom-color-1; color: $custom-white; padding: 45px 15px; margin-top: 100px; }
.home footer .content-footer                    { margin-top: -15px; }
footer .content-footer .data                    { text-align: right; }
footer .content-footer .data .wpb_single_image  { margin-top: 40px; }
footer .content-footer .data .wpb_single_image img  { width: 269px; height: 62px; }
footer .content-footer .data h2                 { font-size: 23px; color: $custom-color-3; }
footer .content-footer .data h2 strong          { background: url(../svgs/icon-phone.svg) left center no-repeat; background-size: 14px 14px; padding-left: 20px; font-weight: normal; }
footer .content-footer .vc_icon_element         { display: inline-block; margin: 0 -16px 0 0; }
footer .content-footer .vc_icon_element.vc_icon_element-outer .vc_icon_element-inner .vc_icon_element-icon::before                              { font-size: 1.4em; }
footer .content-footer .vc_icon_element.vc_icon_element-outer .vc_icon_element-inner.vc_icon_element-color-blue .vc_icon_element-icon           { color: $custom-color-3; }
footer .content-footer .vc_icon_element.vc_icon_element-outer .vc_icon_element-inner.vc_icon_element-color-blue:hover .vc_icon_element-icon     { color: $custom-white; }

footer .content-footer .menus .first                    { margin-left: 5%; }
footer .content-footer .menus .last                     { width: 6.6667%; }

footer .content-footer .widget_nav_menu                 { margin-top: 20px; }
footer .content-footer .widget_nav_menu #menu-pie-3     { padding-top: 28px; }
footer .content-footer .widget_nav_menu h2              { font-size: 17px; font-weight: bold; margin: 0 0 10px 0; text-transform: uppercase; }
footer .content-footer .widget_nav_menu .menu           { list-style: none; margin: 0; padding: 0; }
footer .content-footer .widget_nav_menu .menu a         { font-size: 17px; font-weight: 300; color: $custom-white; }
footer .content-footer .widget_nav_menu .menu a:hover   { color: $custom-color-3; text-decoration: none; }

footer .copyright                   { background: $custom-color-2; padding: 30px 40px; }
footer .copyright p                 { font-size: 14px; color: $custom-white; margin: 0; padding-bottom: 15px; padding-top: 15px; }
footer .copyright .copy a           { font-style: italic; color: rgba($custom-white, 0.4); }
footer .copyright .navbar-nav       { float: right; }
footer .copyright .menu-item a      { font-size: 14px; color: $custom-white; }
footer .copyright .menu-item a:focus    { color: $custom-white; background: none; text-decoration: underline; }
footer .copyright .menu-item a:hover,
footer .copyright .menu-item a:hover:focus    { color: $custom-color-1; background: none; text-decoration: none; }


// #######################################################################
// MEDIA QUERYS
// #######################################################################
/* LG */
@media (min-width: 1200px) and (max-width: 1370px) {
}
@media (min-width: 992px) {
}
/* MD */
@media (min-width: 992px) and (max-width: 1199px) {
    footer .content-footer .vc_icon_element { margin-right: -19px; }
    footer .content-footer .menus .first    { margin-left: 2%; }
}
/* SM - Tablet */
@media (max-width: 991px) {
    footer .content-footer                                              { padding: 35px 25px 10px; }
    footer .content-footer .data .wpb_single_image                      { width: 50%; float: left; margin-top: 0px; margin-bottom: 10px; }
    footer .content-footer .data .wpb_single_image.vc_align_right       { text-align: left; }
    footer .content-footer .data .wpb_text_column                       { width: 50%; display: block; float: left; margin-bottom: 10px; }
    footer .content-footer .data .wpb_text_column h2                    { margin-top: 5px; }
    footer .content-footer .vc_icon_element                             { clear: both; }
    footer .content-footer .menus                                       { margin-top: 20px; }
    footer .content-footer .menus .first                                { margin-left: 0%; }
    footer .content-footer .menus .vc_col-sm-2                          { width: 20%; }

    footer .copyright                       { padding: 15px 15px; }
    footer .copyright p                     { text-align: center; padding-bottom: 10px; padding-top: 5px; }
    footer .copyright .navbar-nav           { float: none; text-align: center; margin-top: 0; }
    footer .copyright .navbar-nav > li      { display: inline-block; }
}
/* XS */
@media (max-width: 767px) {
    footer .content-footer .data .wpb_single_image                      { width: 100%; float: none; margin-bottom: 20px; }
    footer .content-footer .data .wpb_text_column                       { width: 100%; float: none; }
    footer .content-footer .data .wpb_text_column h2                    { text-align: left !important; }
    footer .content-footer .data .vc_icon_element.vc_icon_element-outer.vc_icon_element-align-right { float: left; text-align: left; clear: none; margin-left: -15px; margin-right: 0; }

    footer .content-footer .menus .vc_col-sm-2                          { width: 100%; }
    footer .content-footer .menus .vc_col-sm-2                          { width: 100%; }
    footer .content-footer .menus .widget_nav_menu                      { margin-top: 0px; }
    footer .content-footer .menus .vc_wp_custommenu                     { margin-bottom: 10px; }
    footer .content-footer .widget_nav_menu #menu-pie-3                 { padding-top: 0px; margin-top: -10px; }
    footer .copyright .navbar-nav > li                                  { display: block; }
}