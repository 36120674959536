// Home
.home #news .vc_grid.vc_row.vc_grid-gutter-35px                 { overflow: hidden; }
.home #news .vc_grid.vc_row.vc_grid-gutter-35px .vc_grid-item   { padding-bottom: 105px; }
.home #news h2                                                  { font-weight: 900; text-align: center; text-transform: uppercase; margin: 0; padding: 70px 0; }
.home #news .vc_gitem-post-data                                 { margin-bottom: 5px; }
.home #news h3,
.blog article.hentry h2.entry-title,
.archive article.hentry h2.entry-title,
.search article.hentry h2.entry-title                           { font-size: 27px; font-weight: 900; text-transform: uppercase; margin: 20px 0 5px; }
.home #news .date,
.blog article.hentry time,
.archive article.hentry time,
.search article.hentry time                                     { font-size: 17px; color: $custom-color-6; text-transform: uppercase; margin: 0px; }
.home #news .vc_gitem-zone                                      { background: none !important; }
.home #news .vc_gitem_row .vc_gitem-col                         { padding: 0; }
.home #news .wpb_single_image,
.home #news .wpb_text_column                                    { margin: 0; }
.home #news .wpb_single_image .vc_figure,
.home #news .wpb_single_image .vc_figure a                      { display: inline; }
.home #news .wpb_single_image img                               { width: 100%; }
.home #news .vc_gitem-post-data-source-post_excerpt,
.blog article.hentry .entry-summary,
.archive article.hentry .entry-summary,
.search article.hentry .entry-summary                           { font-size: 16px; font-weight: 300; color: #000000; margin: 25px 0 0; line-height: 1.5em; }
.home #news .ssba,
.blog article.hentry .ssba,
.archive article.hentry .ssba,
.search article.hentry .ssba                                    { margin: 10px 0 0;}

// List
// <link rel="stylesheet" href="/app/plugins/js_composer/assets/lib/bower/animate-css/animate.min.css">
.blog .post-list,
.archive .post-list,
.search .post-list                                              { margin-right: -35px; }
.blog article.hentry,
.archive article.hentry,
.search article.hentry                                          { display: inline-block; padding-bottom: 105px; padding-right: 35px; width: 49.8%; vertical-align: top; position: relative; } 

.search article.hentry.page .text                               { padding: 20px; background: $bkg-grey-1; }
.search article.hentry.page .text h2.entry-title                { margin-top: 0; }

.blog article.hentry .image,
.archive article.hentry .image,
.search article.hentry .image                                   { overflow: hidden; position: relative; }
.blog article.hentry .wrap,
.archive article.hentry .wrap,
.search article.hentry .wrap                                    { position: relative; transform: scale(1); transition: transform 0.3s ease-in-out 0s;  }
.blog article.hentry .image:hover .wrap,
.archive article.hentry .image:hover .wrap,
.search article.hentry .image:hover .wrap                       { transform: scale(1.1); }
.blog article.hentry .wrap::before,
.archive article.hentry .wrap::before,
.search article.hentry .wrap::before                            { padding-top: 56.25%; content: ""; display: block; }

.search article.hentry .label-type                              { font-size: 12px; font-weight: bold; color: $custom-white; padding: 10px; position: absolute; top: 25px; left: 0; background: $custom-color-6; text-transform: uppercase; z-index: 1; }

.blog article.hentry .image .inner,
.archive article.hentry .image .inner,
.search article.hentry .image .inner                            { box-sizing: border-box;padding: inherit; position: static; left: 0; right: 0; bottom: 0; top: 0; }

.blog article.hentry .image a,
.archive article.hentry .image a,
.search article.hentry .image a                                 { display: block; position: absolute; right: 0; left: 0; top: 0; }
.blog article.hentry .image img,
.archive article.hentry .image img,
.search article.hentry .image img                               { width: 100%; height: auto; max-width: 100%; vertical-align: top; }

.blog article.hentry .avatar,
.archive article.hentry .avatar,
.search article.hentry .avatar,
.blog article.hentry .author,
.archive article.hentry .author,
.search article.hentry .author                                  { display: none; }

// Navigation
.posts-navigation h2                                            { display: none; }
.posts-navigation .nav-links                                    { text-align: center; } 
.posts-navigation .nav-links a                                  { font-size: 18px; font-weight: bold; color: $custom-color-1; padding: 10px 30px 12px; background: $custom-color-6; border-top: 2px solid $custom-color-5; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; }
.posts-navigation .nav-previous                                 { display: inline-block; margin-right: 20px; }
.posts-navigation .nav-next                                     { display: inline-block; margin-left: 20px; }

// Post
.single-post h1.entry-title                                     { font-size: 37px; font-weight: 900; text-transform: uppercase; margin: 30px 0 0; }
.single-post time                                               { font-size: 17px; color: $custom-color-6; text-transform: uppercase; margin: 0 0 10px 0; display: inline; }
.single-post .author                                            { font-size: 17px; color: $custom-grey-1; display: inline; }
.single-post .author a                                          { color: $custom-grey-1; }
.single-post .avatar                                            { margin-right: 5px; 
                                                                  -webkit-border-radius: 32px;
                                                                  -moz-border-radius: 32px;
                                                                  border-radius: 32px;}
.single-post .entry-content                                     { font-size: 18px; font-weight: 300; color: $custom-grey-1; margin: 25px 0 0; }
.single-post .image img                                         { width: 100%; height: auto; }
.single-post .ssba-wrap                                         { border-top: 1px solid $bkg-grey-1-border; padding-top: 25px; }
// Backgroun hedaer post
// ***************************
.bkg-single-post                                                { background: $custom-color-1; position: relative; height: 250px; width: 100%; }
.single-post .bkg-single-post                                   { height: 550px; }
.bkg-single-post .inner                                         { width: 100%; height: 100%; opacity: 0.3; }
.bkg-single-post .content                                       { max-width: 600px; padding: 0 30px; position: absolute; top: 50%; left: 50%; text-align: center; width: 100%; }
.bkg-single-post .content                                       { -webkit-transform: translate(-50%, 30%);
                                                                  -ms-transform: translate(-50%, 30%);
                                                                  -o-transform: translate(-50%, 30%);
                                                                  transform: translate(-50%, 30%); 
                                                                }
.single-post .bkg-single-post .content                          { -webkit-transform: translate(-50%, -40%);
                                                                  -ms-transform: translate(-50%, -40%);
                                                                  -o-transform: translate(-50%, -40%);
                                                                  transform: translate(-50%, -40%); 
                                                                }
.bkg-single-post h1                                             { font-size: 28px; color: $custom-white; font-weight: 900; text-transform: uppercase; }
.bkg-single-post .page-header                                   { border-bottom: 0; margin: 0px; padding-bottom: 0px; }
.bkg-single-post .tags                                          { display: inline-block; margin-top: 20px; vertical-align: middle; }
.bkg-single-post .tags a                                        { font-size: 12px; background: $custom-color-6; color: $custom-white; padding: 6px 10px; margin-right: 4px;
                                                                  -webkit-border-radius: 20px;
                                                                  -moz-border-radius: 20px;
                                                                  border-radius: 20px;
                                                                }
// ***************************

// Recent post WIDGET
aside.sidebar .recent-posts-widget-with-thumbnails img                      { width: 100% !important; height: auto !important;  }
aside.sidebar .recent-posts-widget-with-thumbnails .rpwwt-post-title        { font-size: 18px; font-weight: 900; text-transform: uppercase; margin: 30px 0 0; line-height: 1.1; }
aside.sidebar .recent-posts-widget-with-thumbnails .rpwwt-post-date         { font-size: 14px; color: $custom-color-6; text-transform: uppercase; margin: 0px; }

// Related
.single-post .rp4wp-related-posts                                   { border-top: 1px solid $bkg-grey-1-border; padding-top: 45px; margin: 45px 0; overflow: hidden; }
.single-post .rp4wp-related-posts h3                                { color: $custom-color-1; font-size: 18px; font-weight: 900; margin-top: 0; padding: 0 0 35px 40px; background: url(../svgs/icon-last-news.svg) left top 0px no-repeat; background-size: 30px; }
.single-post .rp4wp-related-posts ul                                { margin: 0; padding: 0; list-style: none; }
.single-post .rp4wp-related-posts li                                { width: 33%; float: left; padding-right: 20px; }
.single-post .rp4wp-related-posts .rp4wp-related-post-image         { margin-bottom: 10px; }
.single-post .rp4wp-related-posts .rp4wp-related-post-image img     { width: 100%; height: auto; }
.single-post .rp4wp-related-posts .rp4wp-related-post-content a     { font-size: 16px; font-weight: 900; text-transform: uppercase; margin: 0; line-height: 1.1; display: block; }
.single-post .rp4wp-related-posts .rp4wp-related-post-content p     { font-size: 14px; font-weight: 300; color: $custom-grey-1; margin: 10px 0 0; }


// #######################################################################
// MEDIA QUERYS
// #######################################################################
/* LG */
@media (min-width: 1200px) and (max-width: 1370px) {
}
@media (min-width: 992px) {
}
/* MD */
@media (min-width: 992px) and (max-width: 1199px) {
}
/* SM - Tablet */
@media (max-width: 991px) {
  .archive .post-list,
  .blog .post-list,
  .search .post-list                                                  { margin-right: -24px; }
  .archive article.hentry,
  .blog article.hentry,
  .search article.hentry                                              { padding-right: 30px; }

  .single-post .bkg-single-post                                       { height: 430px; }
}
/* XS */
@media (max-width: 767px) {
  .archive .post-list,
  .blog .post-list,
  .search .post-list                                                  { margin-right: 0px; }
  .archive article.hentry,
  .blog article.hentry,
  .search article.hentry                                              { width: 100%; padding-right: 0; }
  .single-post .rp4wp-related-posts li                                { width: 100%; float: none; padding: 0 0 40px 0; }
}
