// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

// Formulario  Contact Form 7
.checkbox label a   { text-decoration: underline; }
.no-label .wpcf7 label { display: none; }
.no-label .wpcf7 .checkbox label { display: inline-block; }
div.wpcf7 img.ajax-loader    { display: block; text-align: left; float: left; margin: 15px 5px 0; }