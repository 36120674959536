
$ml-1:      260ms;
@mixin s-transition-1 {
    -webkit-transition: all $ml-1 ease-out;
    -moz-transition: all $ml-1 ease-out;
    -ms-transition: all $ml-1 ease-out;
    -o-transition: all $ml-1 ease-out;
    transition: all $ml-1 ease-out; 
}

$ml-2:      250ms;
@mixin s-transition-2 {
    -webkit-transition: all $ml-2 ease;
    -moz-transition: all $ml-2 ease;
    -ms-transition: all $ml-2 ease;
    -o-transition: all $ml-2 ease;
    transition: all $ml-2 ease;
}
$ml-3:      200ms;
@mixin s-transition-3 {
    -webkit-transition: all $ml-3 ease-in-out;
    -moz-transition: all $ml-3 ease-in-out;
    -ms-transition: all $ml-3 ease-in-out;
    -o-transition: all $ml-3 ease-in-out;
    transition: all $ml-3 ease-in-out;
}