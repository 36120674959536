// #######################################################################
// PAGES
// #######################################################################

.page.page-template-monema,
.bbpress                                { background: url(/app/themes/monema-sage/dist/images/bkg-home.webp) left top no-repeat; }
/*.home.page.page-template-monema         { background: none; } //sacamos también para la página principal las nubes */

// main 
.page .main                             { padding-top: 155px; }
.page aside.sidebar                     { padding-top: 155px; }

.home.page .main                        { padding-top: 0px; }
.blog .main,
.archive .main,
.search .main                           { padding-top: 35px; }
.single-post .main                      { padding-top: 35px; }
.single-post aside.sidebar              { padding-top: 85px; }

.bbpress .main,
.bbpress aside.sidebar                  { padding-top: 155px; }

// Header
.page .page-header                      { border: 0; margin: 70px 0 0 0; padding: 0; }
.page .page-header h1                   { font-size: 38px; font-weight: normal; margin: 0 0 10px 0; text-align: center; text-transform: uppercase; }
.page .page-header h1 strong            { font-weight: 900; }
.page .page-header > h1                 { font-weight: 900; }
.page .subtitle h2                      { font-size: 18px; font-weight: normal; margin: 0 0 20px 0; text-align: center; }
.page .title-block                      { margin-bottom: 35px; }

.page-template-monema-sidebar.page .page-header h1,
.page-template-monema-sidebar.page .subtitle h2    { text-align: left; }

// #######################################################################
// BLOCKS
// #######################################################################
// Businnes icons
.business-icons-block                           { text-align: center; }
.business-icons-block .wpb_single_image         { margin: 0; }
.business-icons-block h3                        { font-size: 20px; font-weight: 900; margin: 15 0 10px; }
.business-icons-block img                       { width: 200px; height: 200px; }
.business-icons-block p                         { font-size: 18px; line-height: 1.1; font-weight: 300; }

// Buttons price
.button-price-t-extra-space                     { padding-top: 60px; padding-bottom: 70px; }
.button-price-row.vc_row .vc_column-inner       { padding-left: 15px; padding-left: 15px; }
.button-price-block                             { text-align: center; }
.button-price-block .inline                     { display: inline-block; margin-left: 15px; }
.button-price-block .inline h4                  { font-size: 18px; font-weight: bold; font-style: italic; color: $custom-color-6; margin-top: 15px; }
.button-price-block .inline h4 em               { font-size: 11px; font-weight: normal; }

// Play video
.play-video                                     { text-align: right; }
.play-video a                                   { color: $custom-white; background: url(/app/themes/monema-sage/dist/svgs/icon-play.svg) left center no-repeat; background-size: 70px 70px; padding: 40px 0 40px 70px; }

// Video row
.video-row                                      { background: $custom-color-1 url(/app/themes/monema-sage/dist/images/bkg-row-video.webp) center top / cover no-repeat; color: $custom-white; min-height: 250px; margin-bottom: 60px; }
.video-row .wpb_text_column                     { margin: 0; }
.video-row h3                                   { font-weight: 900; margin: 0 0 0 30px; }
.video-row h3 em                                { font-size: 18px; font-weight: normal; font-style: normal; line-height: 1.7; }

// table all in
.table-all-in                                   { text-align: center; }
.table-condensed>tbody>tr>td,
.table-condensed>tbody>tr>th,
.table-condensed>tfoot>tr>td,
.table-condensed>tfoot>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>thead>tr>th                    { padding: 2px; }
.table-all-in .table>thead>tr>th                { text-align: center; border: 0; font-size: 16px; }
.table-all-in .table>tbody>tr>td                { border: 0; }
.table-all-in tbody tr td:first-child           { text-align: left; }
.table-all-in tbody tr td:last-child            { font-weight: bold; }
.table-all-in .icon                             { display: inline-block; width: 20px; height: 20px; text-indent: -4000px; outline: 0; background: url(/app/themes/monema-sage/dist/svgs/icon-check.svg) left center no-repeat; background-size: 20px 20px;   }

// Image buildings
.bkg-building-2a                                { background: url(/app/themes/monema-sage/dist/images/image-building-2a.webp) right bottom no-repeat; }
.bkg-building-2b                                { background: url(/app/themes/monema-sage/dist/images/image-building-2b.webp) right top no-repeat; }

// title-h3-icon
.title-h3-icon.center                           { text-align: center; }
.title-h3-icon h3                               { display: inline-block; font-weight: bold; font-size: 32px; padding: 5px 0 5px 50px; background: url(/app/themes/monema-sage/dist/svgs/icon-title.svg) left top no-repeat; background-size: 45px 45px; }
.title-h3-icon p                                { padding: 0 50px; }
.title-h3-icon.icon-2 h3                        { background: url(/app/themes/monema-sage/dist/svgs/icon-title-2.svg) left top no-repeat; background-size: 45px 45px; }

// features tour
.features-tour                                  { padding-top: 50px; padding-bottom: 30px; background: $bkg-grey-1; border-bottom: 1px solid $bkg-grey-1-border; }
.features-tour-icons                            {}
.features-tour-icons .vc_icon_element           { position: absolute; top: 10px !important; left: 0;}
.features-tour-icons h3                         { color: $custom-color-1; font-size: 20px; font-weight: bold; color: $custom-color-1; padding: 8px 0 0 40px; }
.features-tour-icons p                          { color: $custom-color-1; }
.features-tour-icons a                          { color: $custom-color-6; }
.features-tour .button-price-row                { padding-top: 30px; }

.row-only-text                                  { padding-top: 40px; padding-bottom: 40px; }

// Modal




// #######################################################################
// HOME
// #######################################################################

// Header
.home .page-header                      { display: none; }

// Slider
.home #home-carousel-slider-mobile      { display: none; }
.home #home-carousel-slider             { background: url(/app/themes/monema-sage/dist/images/bkg-home.webp) 0 0 no-repeat;
                                        -webkit-transition: background-position 550ms ease-out;
                                        -moz-transition: background-position 550ms ease-out;
                                        -ms-transition: background-position 550ms ease-out;
                                        -o-transition: background-position 550ms ease-out;
                                        transition: background-position 550ms ease-out;
                                        }
.home #home-carousel-slider.step0       { background-position: 0 0 ; }
.home #home-carousel-slider.step1       { background-position: -140px 0 ; }
.home #home-carousel-slider.step2       { background-position: -500px 0 ; }

.home #home-carousel-images .wpb_single_image.step0,
.home #home-carousel-images .wpb_single_image.step2
                                        {
                                        -webkit-transition: all 850ms ease;
                                        -moz-transition: all 850ms ease;
                                        -ms-transition: all 850ms ease;
                                        -o-transition: all 850ms ease;
                                        transition: all 850ms ease;
                                        }

.home #home-carousel,
.home #home-carousel .carousel-inner                            { min-height: 605px; overflow: hidden; }
.home #home-carousel .item                                      { min-height: 350px; top: 240px; z-index: 4; }
.home #home-carousel .item .wpb_text_column                     { margin: 0; }
.home #home-carousel .item h2                                   { font-size: 40px; margin: 0 0 20px 0; }
.home #home-carousel .item h2 strong                            { font-weight: 900; }
.home #home-carousel .item p                                    { font-size: 15px; margin: 0 0 20px 0; }
.home #home-carousel .item li                                   { font-size: 18px; margin: 0 0 6px 0; }
.home #home-carousel .item .icon-list ul                        { margin: 0; padding: 0; list-style: none; }
.home #home-carousel .item .icon-list li                        { font-size: 24px; font-weight: 300; display: inline; margin-right: 15px; padding: 15px 0 15px 60px; }
.home #home-carousel .item .icon-list li:nth-child(1)           { background: url(/app/themes/monema-sage/dist/svgs/icon-list-home-1.svg) left center no-repeat; background-size: 65px 65px;  }
.home #home-carousel .item .icon-list li:nth-child(2)           { background: url(/app/themes/monema-sage/dist/svgs/icon-list-home-2.svg) left center no-repeat; background-size: 65px 65px;  }
.home #home-carousel .item .icon-list li:nth-child(3)           { background: url(/app/themes/monema-sage/dist/svgs/icon-list-home-3.svg) left center no-repeat; background-size: 65px 65px;  }
.home #home-carousel.lazymonema .item .icon-list li:nth-child(1)           { background: none; background-size: 65px 65px;  }
.home #home-carousel.lazymonema .item .icon-list li:nth-child(2)           { background: none; background-size: 65px 65px;  }
.home #home-carousel.lazymonema .item .icon-list li:nth-child(3)           { background: none; background-size: 65px 65px;  }
.home #home-carousel .item .button-price-block                  { text-align: left; }
.home #home-carousel .item .button-price-block .inline          { margin-left: 15px; }
.home #home-carousel .item .multi-list ul                       { float: left; margin-top: 30px; }
.home #home-carousel .item:nth-child(1)                         { padding-left: 50%; padding-right: 15%; }
.home #home-carousel .item:nth-child(2)                         { padding-left: 35%; padding-right: 15%; }
.home #home-carousel .item:nth-child(3)                         { padding-left: 25%; padding-right: 40%; }
.home #home-carousel .carousel-indicators                       { bottom: auto; top: 120px; margin: 0; padding: 20px; margin-left: -50%; width: 100%; z-index: 4; }
.home #home-carousel .carousel-indicators li                    { border-color: $custom-color-4; }
.home #home-carousel .carousel-indicators li.active             { background-color: $custom-color-4; }
.home #home-carousel .carousel-control                          { display: none; }

.home #home-carousel-images                                     { min-height: 1000px; position: absolute !important; top: 0;/* z-index: 3;*/ }
.home #home-carousel-images .wpb_single_image.step0,
.home #home-carousel-images.step0 .wpb_single_image.step0       { position: absolute; top: 164px; left: 0; z-index: 2; }
.home #home-carousel-images.step1 .wpb_single_image.step0       { left: -320px; }
.home #home-carousel-images.step2 .wpb_single_image.step0       { left: -1500px; }

.home #home-carousel-images .wpb_single_image.step2,
.home #home-carousel-images.step0 .wpb_single_image.step2       { position: absolute; top: 164px; right: -1500px; z-index: 2; }
.home #home-carousel-images.step1 .wpb_single_image.step2       { }
.home #home-carousel-images.step2 .wpb_single_image.step2       { right: -50px; }

.home #tryit h2                                   { font-size: 40px; margin: 0 0 20px 0; }
.home #tryit h2 strong                            { font-weight: 900; }
.home #tryit p                                    { font-size: 15px; margin: 0 0 20px 0; }
.home #tryit li                                   { font-size: 18px; margin: 0 0 6px 0; }
.home #tryit .icon-list ul                        { margin: 0; padding: 0; list-style: none; }
.home #tryit .icon-list li                        { font-size: 24px; font-weight: 300; display: block; margin-right: 15px; padding: 15px 0 15px 60px; }
.home #tryit .icon-list li:nth-child(1)           { background: url(/app/themes/monema-sage/dist/svgs/icon-list-home-1.svg) left center no-repeat; background-size: 65px 65px;  }
.home #tryit .icon-list li:nth-child(2)           { background: url(/app/themes/monema-sage/dist/svgs/icon-list-home-2.svg) left center no-repeat; background-size: 65px 65px;  }
.home #tryit .icon-list li:nth-child(3)           { background: url(/app/themes/monema-sage/dist/svgs/icon-list-home-3.svg) left center no-repeat; background-size: 65px 65px;  }
.home #tryit.lazymonema .icon-list li:nth-child(1)           { background: none; background-size: 65px 65px;  }
.home #tryit.lazymonema .icon-list li:nth-child(2)           { background: none; background-size: 65px 65px;  }
.home #tryit.lazymonema .icon-list li:nth-child(3)           { background: none; background-size: 65px 65px;  }
.home #home-carousel .button-price-block                  { text-align: left; }
.home #home-carousel .button-price-block .inline          { margin-left: 15px; }
.home #home-carousel .multi-list ul                       { float: left; margin-top: 30px; }


// Featured
.home #featured                         { background: $custom-color-1; color: $custom-white; }
.home #featured .col-1                  { background: $custom-color-6; margin: 0 2% 0 0; }
.home #featured .col-2                  { background: $custom-color-3; margin: 0 1% 0 1%; }
.home #featured .col-3                  { background: $custom-color-4; margin: 0 0 0 2%; }
.home #featured .wpb_column             { padding: 30px 20px 10px 30px; width: 31.3333%; margin-top: 110px; margin-bottom: 110px; -webkit-border-radius: 7px; -moz-border-radius: 7px; border-radius: 7px; z-index: 3; }
.home #featured .wpb_column > .vc_column-inner { padding: 0; }
.home #featured .wpb_single_image       { width: 30%; float: left; padding-right: 5%; }
.home #featured .wpb_single_image img   { width: 70px; height: 70px; background: rgba(255,255,255,0.1); padding: 5px; -webkit-border-radius: 100px; -moz-border-radius: 100px; border-radius: 100px; }
.home #featured .wpb_text_column        { width: 65%; float: left; margin: 0; }
.home #featured h2                      { font-size: 24px; margin: 0 0 15px 0; text-transform: uppercase; }
.home #featured p                       { font-size: 14px; margin: 0 0 15px 0; }
.home #featured a                       { color: $custom-color-1; background: url(/app/themes/monema-sage/dist/svgs/icon-more.svg) left top 4px no-repeat; background-size: 10px 10px; padding-left: 15px; }

// Business
.home #business                         { text-align: center; padding: 70px 0; }
.home #business .wpb_single_image,
.home #business .wpb_text_column        { margin: 0; }
.home #business h2                      { margin: 0 0 20px 0; }
.home #business h2 strong               { font-weight: 900; }
.home #business p                       { font-size: 18px; margin: 0  0 20px 0; }
.home #business .business-icons         {}
.home #business .business-icons img     { width: 200px; height: 200px; }
.home #business .business-icons p       { font-size: 26px; line-height: 1.1; font-weight: 300; }

// Try it free
.home #tryit                            { background: $bkg-grey-1 url(/app/themes/monema-sage/dist/images/bkg-tryit-1.webp) left top no-repeat; min-height: 520px; }
.home #tryit.lazymonema                 { background: none; min-height: 520px; }

// Service
.page #service                          { margin-top: 70px; overflow: visible !important; }
.page #customers-form                   { margin-top: 70px; overflow: visible !important; }

// Service
.page #customers-form                   { margin-top: 70px; overflow: visible !important; }
.home.page #customers-form              { margin-top: 0; }

// ekomi stars
.home #ekomi                            { padding: 90px 0 35px; background: $bkg-grey-1; }
// ekomi quotes
.home #quotes                           { padding: 35px 0 90px; background: $bkg-grey-1; border-bottom: 1px solid $bkg-grey-1-border; }

// Customers
.home #customers                        { z-index: 2; background: url(/app/themes/monema-sage/dist/images/bkg-column-right-1.webp) right top no-repeat; }
.home #customers .bkg                   { min-height: 432px; }


// #######################################################################
// MEDIA QUERYS
// #######################################################################
/* LG */
@media (min-width: 1200px) and (max-width: 1370px) {
    .home #home-carousel,
    .home #home-carousel .carousel-inner                            { min-height: 478px; }
    .home #home-carousel .item                                      { min-height: 150px; top: 200px; }
    .home #home-carousel-images                                     { min-height: 800px; }
    .home #home-carousel-images .wpb_single_image.step0,
    .home #home-carousel-images.step0 .wpb_single_image.step0,
    .home #home-carousel-images .wpb_single_image.step2,
    .home #home-carousel-images.step0 .wpb_single_image.step2       { top: 184px; }
    .home #home-carousel-images .wpb_single_image.step0 img,
    .home #home-carousel-images .wpb_single_image.step2 img         { width: 750px; height: auto; }
    .home #home-carousel .item:nth-child(1)                         { padding-left: 50%; padding-right: 5%; }
    .home #home-carousel .item:nth-child(2)                         { padding-left: 30%; padding-right: 5%; }
    .home #home-carousel .item:nth-child(3)                         { padding-left: 15%; padding-right: 30%; }

    .home #tryit                                                    { background-position: left -200px top;  }
}
@media (min-width: 992px) {
}
/* MD */
@media (min-width: 992px) and (max-width: 1199px) {
    .home #home-carousel,
    .home #home-carousel .carousel-inner                            { min-height: 470px; }
    .home #home-carousel .item                                      { min-height: 150px; top: 200px; }
    .home #home-carousel-images                                     { min-height: 800px; }
    .home #home-carousel-images .wpb_single_image.step0,
    .home #home-carousel-images.step0 .wpb_single_image.step0,
    .home #home-carousel-images .wpb_single_image.step2,
    .home #home-carousel-images.step0 .wpb_single_image.step2       { top: 264px; }
    .home #home-carousel-images .wpb_single_image.step0 img,
    .home #home-carousel-images .wpb_single_image.step2 img         { width: 550px; height: auto; }
    .home #home-carousel .item:nth-child(1)                         { padding-left: 45%; padding-right: 5%; }
    .home #home-carousel .item:nth-child(2)                         { padding-left: 20%; padding-right: 5%; }
    .home #home-carousel .item:nth-child(3)                         { padding-left: 15%; padding-right: 30%; }

    .home #tryit                            { background-position: left -230px top; }
    .home #featured .wpb_column             { padding: 30px 10px 10px 20px; }
    .home #featured .wpb_single_image img   { width: 60px; height: 60px; }
}
/* SM - Tablet */
@media (max-width: 991px) {

    .home #home-carousel,
    .home #home-carousel .carousel-inner                            { min-height: 519px; }
    .home #home-carousel .item                                      { min-height: 150px; top: 200px; }
    .home #home-carousel .item .icon-list li                        { display: block; padding: 5px 0 5px 60px; }
    .home #home-carousel-images                                     { min-height: 800px; }
    .home #home-carousel-images .wpb_single_image.step0,
    .home #home-carousel-images.step0 .wpb_single_image.step0,
    .home #home-carousel-images .wpb_single_image.step2,
    .home #home-carousel-images.step0 .wpb_single_image.step2       { top: 334px; }
    .home #home-carousel-images .wpb_single_image.step0 img,
    .home #home-carousel-images .wpb_single_image.step2 img         { width: 500px; height: auto; }
    .home #home-carousel .item:nth-child(1)                         { padding-left: 35%; padding-right: 5%; }
    .home #home-carousel .item:nth-child(2)                         { padding-left: 20%; padding-right: 20%; }
    .home #home-carousel .item:nth-child(3)                         { padding-left: 5%; padding-right: 20%; }

    .home #business .business-icons img     { height: 140px; width: 140px; }

    .home #tryit                            { background-position: left -350px top; padding-left: 30px; padding-right: 30px;  }

    .home #featured .wpb_column             { padding-left: 20px; }
    .home #featured .wpb_single_image       { text-align: center; float: none; padding-right: 0%; width: 100%;  margin-bottom: 15px; }
    .home #featured .wpb_text_column        { float: none; width: 100%; }

    .home #customers                        { background-position: right -130px top; }
}
/* XS */
/* Lo hemos actualizado porque habíamos quitado el carrousel. Ahora la cabecera de la página inicial se ve como si fuese versión mobile hasta 5000px */
@media (max-width: 5000px) {
    .single-post aside.sidebar                              { padding-top: 25px; }

    .home #home-carousel-slider,
    .home #home-carousel-images                             { display: none; }
    .home #home-carousel-slider-mobile                      { display: flex; text-align: center; background-size: 500px auto; background-position: left -70px bottom -270px !important; }
    .home #home-carousel-slider-mobile .wpb_text_column     { margin: 0; }
    .home #home-carousel-slider-mobile h2                   { font-size: 30px; margin: 0 0 20px 0; }
    .home #home-carousel-slider-mobile h2 strong            { font-weight: 900; }
    .home #home-carousel-slider-mobile p                    { font-size: 16px; margin: 0 0 20px 0; }

    .home #tryit .content                                   { background: rgba($custom-white,0.9); margin: 0 20px; -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px; }

    .home #customers                                        { padding: 0 30px; background: none; }
    .home #home-carousel-slider-mobile                       { background-image: none !important; margin-top: 100px;}
}

/* Esto son las columnas de debajo de la cabecera de la página inicial */
@media (max-width: 767px) {
    .home #featured .wpb_column                             { width: 100%; padding: 20px; margin: 0 15px 30px; }
    .home #featured                                         { padding-top: 30px;}
    .home #tryit .vc_column-inner                           { padding-left: 30px; padding-right: 30px};
}
