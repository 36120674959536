// Common
aside.sidebar section.widget            { margin-bottom: 0px; }
aside.sidebar .widget_categories,
aside.sidebar .widget_archive,
aside.sidebar .shailan_SubpagesWidget   { color: $custom-white; padding: 20px 25px 50px; background: url(../images/bkg-widgets-1.webp) left bottom no-repeat; }
aside.sidebar .widget_categories h3,
aside.sidebar .widget_archive h3,
aside.sidebar .recent-posts-widget-with-thumbnails h3        { font-size: 18px; font-weight: 900; margin-top: 0; }
aside.sidebar .widget_categories ul,
aside.sidebar .widget_archive ul,
aside.sidebar .shailan_SubpagesWidget ul    { margin: 0; padding: 0; list-style: none; }
aside.sidebar .widget_categories li,
aside.sidebar .widget_archive li,
aside.sidebar .shailan_SubpagesWidget li    { padding: 10px 2px; }
aside.sidebar .widget_categories li a,
aside.sidebar .widget_archive li a,
aside.sidebar .shailan_SubpagesWidget li a  { color: rgba($custom-white, 0.7); text-transform: uppercase; }
aside.sidebar .shailan_SubpagesWidget li.current_page_item a { color: rgba($custom-white, 1); }

// Categories
aside.sidebar .widget_categories        { background-color: $custom-color-1; }
aside.sidebar .widget_categories ul     { border-top: 1px solid lighten( $custom-color-1, 3% ); }
aside.sidebar .widget_categories li     { border-bottom: 1px solid lighten( $custom-color-1, 3% ); }

// Archive
aside.sidebar .widget_archive           { background-color: $custom-color-6; }
aside.sidebar .widget_archive ul        { border-top: 1px solid darken( $custom-color-6, 7% ); }
aside.sidebar .widget_archive li        { border-bottom: 1px solid darken( $custom-color-6, 7% ); }

// Recent post
aside.sidebar .recent-posts-widget-with-thumbnails      { background: $bkg-grey-1; padding: 20px 25px 50px; }
aside.sidebar .recent-posts-widget-with-thumbnails h3   { padding: 0 0 10px 40px; background: url(../svgs/icon-last-news.svg) left top 0px no-repeat; background-size: 30px; }
aside.sidebar .recent-posts-widget-with-thumbnails ul   { margin: 0; }

// TEMPLATERA
aside.sidebar .widget_np_templatera_widget .templatera_shortcode > .vc_row      { margin: 0; }
// Ekomi stars & quotes
aside.sidebar .widget_np_templatera_widget .ekomi_stars,
aside.sidebar .widget_np_templatera_widget .ekomi_quotes,    { background: $bkg-grey-1; padding: 20px 25px 25px; }
aside.sidebar .widget_np_templatera_widget .skeepers_quotes,    { background: $bkg-grey-1; padding: 20px 25px 25px; max-height: 600px }

// Submenus
aside.sidebar section.widget.shailan_SubpagesWidget         { background-color: $custom-color-1; padding-top: 35px; margin-bottom: 40px; }
aside.sidebar .shailan_SubpagesWidget ul                    { border-top: 1px solid lighten( $custom-color-1, 3% ); }
aside.sidebar .shailan_SubpagesWidget li                    { border-bottom: 1px solid lighten( $custom-color-1, 3% ); }
aside.sidebar .shailan_SubpagesWidget li a                  { font-size: 16px; }

// #######################################################################
// MEDIA QUERYS
// #######################################################################
/* LG */
@media (min-width: 1200px) and (max-width: 1370px) {
}
@media (min-width: 992px) {
}
/* MD */
@media (min-width: 992px) and (max-width: 1199px) {
}
/* SM - Tablet */
@media (max-width: 991px) {
    aside.sidebar .recent-posts-widget-with-thumbnails      { display: none; }
}
/* XS */
@media (max-width: 767px) {
    aside.sidebar .widget_np_templatera_widget .ekomi_stars,
    aside.sidebar .widget_np_templatera_widget .ekomi_quotes,    { padding: 20px 15px 25px; }
    aside.sidebar .widget_np_templatera_widget .skeepers_quotes,    { padding: 20px 15px 25px; }
}
