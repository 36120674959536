@media(min-width:992px){ // Sólo cargamos lato cuando estamos en un ordenador. Es para agilizar en móvil la carga
/* lato-300 - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-display: optional;
  src: url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-300.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-300italic - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  font-display: optional;
  src: url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-300italic.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-regular - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-italic - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  font-display: optional;
  src: url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-italic.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-700 - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: optional;
  src: url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-700.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-700italic - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  font-display: optional;
  src: url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-700italic.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-900 - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-display: optional;
  src: url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-900.woff') format('woff'), /* Modern Browsers */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-900.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-900italic - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 900;
  font-display: optional;
  src: url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-900italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-900italic.woff') format('woff'), /* Modern Browsers */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/app/themes/monema-sage/dist/fonts/lato-v23-latin-ext_latin-900italic.svg#Lato') format('svg'); /* Legacy iOS */
}
}

// Cargamos el tipo de letra desde el head.php con preload para evitar que cambie la página una vez cargada
//@import url(https://fonts.googleapis.com/css?family=Lato:400,400italic,700,700italic,300italic,300,900&display=block);
body            {
  font-family: "Lato",Arial,Georgia,Serif;
}

// General
body            { color: $custom-color-1; background: $custom-white; }
a, a:focus      { outline: 0; }

// Layout
.wrap.container                                         { min-height: 800px; }

// Button
.vc_btn3.vc_btn3-color-warning.vc_btn3-style-flat,
.vc_btn3.vc_btn3-color-info.vc_btn3-style-flat,
.wpcf7-form-control.btn.btn-info                        { font-size: 18px; line-height: 1.2; font-weight: bold; color: $custom-color-1; padding: 10px 30px 12px; border: 0; border-top: 2px solid $custom-color-3; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; }
.vc_btn3.vc_btn3-color-warning.vc_btn3-style-flat       { background-color: $custom-color-6; border-color: $custom-color-5; }
.vc_btn3.vc_btn3-color-info.vc_btn3-style-flat,
.wpcf7-form-control.btn.btn-info                        { background-color: $custom-color-3; border-color: $custom-color-2; }
.wpcf7 input[type="submit"]                             { padding: 10px; border: 2px solid $custom-color-5; border-radius: 10px; font-weight: bold; }

// #######################################################################
// MEDIA QUERYS
// #######################################################################
/* LG */
@media (min-width: 1200px) and (max-width: 1370px) {
}
@media (min-width: 992px) {
    // Botones prueba gratis y solicita demo.
    #menu-primary-navigation .btn_menu_a a                                      { background-color: transparent; }
    #menu-primary-navigation .btn_menu_a a:hover                                { background-color: #f68e32; }
    #menu-primary-navigation .btn_menu_a a, .btn_menu_b a                       { padding: 0px; border: 2px solid $custom-color-5; border-radius: 10px; }
    #menu-primary-navigation .btn_menu_b a                                      { background-color: #f68e32; margin-left: 5px; }
    #menu-primary-navigation .btn_menu_b a:hover, .btn_menu_b a:link            { color: black !important; }
    #menu-primary-navigation .menu-item a                                       { padding: 15px 10px !important;}
}
/* MD */
@media (min-width: 992px) and (max-width: 1199px) {
}
/* SM - Tablet */
@media (max-width: 991px) {
}
/* XS */
@media (max-width: 767px) {
    .vc_btn3.vc_btn3-color-warning.vc_btn3-style-flat,
    .vc_btn3.vc_btn3-color-info.vc_btn3-style-flat,
    .wpcf7-form-control.btn.btn-info                        { font-size: 14px; }
}
