// ACORDEON
.vc_tta-container .vc_tta .vc_active .vc_tta-controls-icon.vc_tta-controls-icon-chevron::before {
    -webkit-transform: rotate(45deg) translate(-25%,-25%);
    -ms-transform: rotate(45deg) translate(-25%,-25%);
    transform: rotate(45deg) translate(-25%,-25%);
}
.vc_tta-container .vc_tta .vc_tta-controls-icon.vc_tta-controls-icon-chevron::before {
    -webkit-transform: rotate(-45deg) translate(-25%,-25%);
    -ms-transform: rotate(-45deg) translate(-25%,-25%);
    transform: rotate(-45deg) translate(-25%,-25%);
}
.vc_tta-container .vc_tta-color-white.vc_tta-style-flat .vc_tta-panel .vc_tta-panel-heading {
    background: none; border: 1px solid $bkg-grey-1-border;
}
.vc_tta-container .vc_tta.vc_tta-accordion .vc_tta-controls-icon-position-left .vc_tta-controls-icon {
    left: 15px;
}
.vc_tta-container .vc_tta.vc_tta-accordion .vc_tta-controls-icon-position-left.vc_tta-panel-title>a {
    padding-left: 35px;
}
.vc_tta-container .vc_tta-color-white.vc_tta-style-flat .vc_tta-panel .vc_tta-panel-title>a {
    color: $custom-color-1; font-size: 20px; font-weight: bold;
}
.vc_tta-container .vc_tta-color-white.vc_tta-style-flat .vc_tta-panel.vc_active .vc_tta-panel-title>a {
    color: $custom-color-2;    
}
.vc_tta-container .vc_tta-color-white.vc_tta-style-flat .vc_active .vc_tta-panel-heading .vc_tta-controls-icon::after,
.vc_tta-container .vc_tta-color-white.vc_tta-style-flat .vc_active .vc_tta-panel-heading .vc_tta-controls-icon::before {
    border-color: $custom-color-2;   
}
.vc_tta-container .vc_tta-color-white.vc_tta-style-flat .vc_tta-controls-icon::after,
.vc_tta-container .vc_tta-color-white.vc_tta-style-flat .vc_tta-controls-icon::before {
    border-color: $custom-color-1; 
}
.vc_tta-container .vc_tta-color-white.vc_tta-style-flat:not(.vc_tta-o-no-fill) .vc_tta-panel-body {
    color: $custom-grey-1; 
}

// TOUR
.vc_tta-container .vc_tta-tabs.vc_tta-tabs-position-left .vc_tta-tabs-list {
    // margin-top: 40px;
}
.vc_tta-container .vc_tta.vc_general .vc_tta-panel-body {
    padding: 25px 40px;
}
.vc_tta-container .vc_tta-tabs.vc_tta-tabs-position-left .vc_tta-panels-container {
    border: 1px solid $bkg-grey-1-border; border-left: 0;
}
.vc_tta-container .vc_tta.vc_tta-spacing-1 .vc_tta-tab {
    // margin: -1px 0 0 1px;
}
.vc_tta-container .vc_tta-color-white.vc_tta-style-flat .vc_tta-tab>a {
    color: $custom-color-1; font-size: 20px; border: 1px solid transparent; border-bottom: 1px solid $bkg-grey-1-border;
}
.vc_tta-container .vc_tta-color-white.vc_tta-style-flat .vc_tta-tab.vc_active>a {
    color: $custom-color-1; font-weight: bold; border: 1px solid $bkg-grey-1-border;
}
.vc_tta-container .vc_icon_element.vc_icon_element-outer .vc_icon_element-inner.vc_icon_element-color-blue .vc_icon_element-icon {
    color: $custom-color-1;
}