// Header
header.navbar               { /*background: rgba($custom-white, 0.95);*/ padding: 40px 0; margin: 0; border-bottom: 1px solid $bkg-grey-1-border; -webkit-backface-visibility:hidden; -webkit-transform: translateZ(0);
                              @include s-transition-1; }
.home header.navbar         { background: none; }
header.navbar.scroll        { background: rgba($custom-white, 0.95); padding: 10px 0; }

// Brand
.navbar-brand                           { margin-top: 5px; width: 300px; height: 70px; background: url(/app/themes/monema-sage/dist/svgs/monema.svg) no-repeat; background-size: 300px 70px; text-indent: -4000px;
                                          @include s-transition-1; }
header.navbar.scroll .navbar-brand      { width: 110px; height: 26px; background-size: 110px 26px; margin-top: 18px; }

// Menu
.nav-primary .navbar-nav                        { margin-top: 10px; }
.nav-primary .navbar-nav a                      { font-size: 14.3px; text-transform: uppercase; }
.nav-primary .navbar-nav  > li.active > a       { font-weight: bold; }
.nav-primary .navbar-nav > li > a:focus         { background: none; text-decoration: underline; }
.nav-primary .navbar-nav > li > a:hover,
.nav-primary .navbar-nav > li > a:hover:focus   { background: $bkg-grey-1; text-decoration: none; }
header.navbar.scroll .nav-primary .navbar-nav   { margin-top: 0px; }
.nav-primary .navbar-nav > li.mobile            { display: none; }
// Dropdown
.nav-primary .navbar-nav > li.open > a                 { background: $bkg-grey-1; -webkit-box-shadow: 0 3px 2px 0 rgba(0,0,0,0.1); box-shadow: 0 3px 2px 0 rgba(0,0,0,0.1); }
.nav-primary .navbar-nav > li.open > a,
.nav-primary .navbar-nav > li.open > a:focus           { text-decoration: none; color: $custom-color-2; }
.navbar-nav > li > .dropdown-menu               { border: 0; padding: 0; background: $bkg-grey-1; -webkit-box-shadow: 0 3px 2px 0 rgba(0,0,0,0.1); box-shadow: 0 3px 2px 0 rgba(0,0,0,0.1); }
.navbar-nav .dropdown-menu > li > a             { font-size: 16px; padding: 10px 13px; }
.navbar-nav .dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover              { background: none; color: $custom-color-2; }

// Utils
.utils                                  { position: absolute; top: 10px; right: 20px; z-index: 1031; @include s-transition-1; }
header.navbar.scroll .utils             { display: none; }
.utils form                             { display: inline; }
.utils form .search-submit              { display: none; }
.utils form label span                  { display: none; }
.utils form .search-field               { color: $custom-color-1; width: 140px; background: none; border: 0; border-bottom: 1px solid $bkg-grey-1-border; box-shadow: none; -webkit-border-radius: 0px; -moz-border-radius: 0px; border-radius: 0px; }
.utils form .search-field:focus         { background: none; border: 0; border-bottom: 1px solid $custom-color-1; box-shadow: none; -webkit-border-radius: 0px; -moz-border-radius: 0px; border-radius: 0px; }
.utils ul#menu-secondary-navigation     { list-style: none; display: inline; margin: 0; padding: 0; }
.utils ul li.menu-item                  { display:  inline; border-right: 1px solid $bkg-grey-1-border; }
.utils ul li.menu-item:last-child       { border: 0; }
.utils ul li.menu-item > a              { padding: 3px 6px; }
.utils ul li.menu-item > a:focus        { text-decoration: none; } 
.utils ul li.menu-item.dropdown > a     { width: 40px; }
.utils ul li.menu-item.open > a         { background: $custom-color-1; color: $custom-white; -webkit-border-top-left-radius: 4px;
-webkit-border-top-right-radius: 4px;
-moz-border-radius-topleft: 4px;
-moz-border-radius-topright: 4px;
border-top-left-radius: 4px;
border-top-right-radius: 4px; }
.utils ul li.menu-item.open > .dropdown-menu  { background: $custom-color-1; margin: 0; padding: 0; border: 0; width: 40px; min-width: 40px; -webkit-border-top-left-radius: 0px; -webkit-border-top-right-radius: 0px; -moz-border-radius-topleft: 0px; -moz-border-radius-topright: 0px; border-top-left-radius: 0px; border-top-right-radius: 0px; }
.utils ul li.menu-item.open > .dropdown-menu > li > a { color: $custom-white; padding: 4px 5px; }
.utils ul li.menu-item.open > .dropdown-menu > li > a:hover,
.utils ul li.menu-item.open > .dropdown-menu > li > a:focus,
.utils ul li.menu-item.open > .dropdown-menu > li > a:hover:focus   { background: none; text-decoration: underline; }
.utils ul li.menu-item.open > .dropdown-menu > li > a:focus         { text-decoration: none; }

// Post
.header-bkg-post  header.navbar .navbar-brand                             { background-image: url(/app/themes/monema-sage/dist/svgs/monema_white.svg); }
.header-bkg-post  header.navbar.scroll .navbar-brand                      { background-image: url(/app/themes/monema-sage/dist/svgs/monema.svg); }
.header-bkg-post  header.navbar                                           { border: 0; background: rgba(255, 255, 255, 0); }
.header-bkg-post  header.navbar.scroll                                    { background: rgba(255, 255, 255, 0.95); }                  
.header-bkg-post  header.navbar .utils #monema-phone-number-utils-span,
.header-bkg-post  header.navbar .utils #monema-phone-number-utils2-span,
.header-bkg-post  header.navbar .utils #monema-email-utils-span,
.header-bkg-post  header.navbar .utils #monema-phone-number-utils,
.header-bkg-post  header.navbar .utils #monema-phone-number-utils2,
.header-bkg-post  header.navbar .utils #monema-email-utils,
.header-bkg-post  header.navbar .utils form .search-field,
.header-bkg-post  header.navbar .navbar-nav > li > a,
.header-bkg-post  header.navbar .utils ul li.menu-item > a                { color: $custom-white !important; }
.header-bkg-post  header.navbar .navbar-nav > li.active > a               { font-weight: bold; }
.header-bkg-post  header.navbar .navbar-nav > li >  a:hover,
.header-bkg-post  header.navbar .navbar-nav > li >  a:focus,
.header-bkg-post  header.navbar .navbar-nav > li >  a:hover:focus,
.header-bkg-post  header.navbar.scroll .navbar-nav > li >  a,
.header-bkg-post  header.navbar.scroll .utils ul li.menu-item > a         { color: inherit !important; }


// Números de teléfono y email. En la parte de Utils y en la barra superior que aparece en versión móvil (está metido todo en templates/header.php)

#mobile-info-bar { margin: auto; background-color: #2E3F4F; text-align: left; color: #ffffff; display:none; padding-left: 6px; position: absolute; width: calc(100% - 50px); height: 46px; z-index: 1032;}
#monema-phone-number-utils { margin-left: 6px; margin-right: 6px;}
#monema-phone-number-utils2 { margin-left: 6px; margin-right: 6px;}
#monema-email-utils { margin-left: 6px; margin-right: 6px;}
#monema-phone-number-topbar { margin-left: 6px; margin-right: 6px; color: #ffffff;}
#monema-phone-number-topbar2 { margin-left: 6px; margin-right: 6px; color: #ffffff;}
#monema-email-topbar { margin-left: 6px; margin-right: 6px; color: #ffffff;}

// #######################################################################
// MEDIA QUERYS
// #######################################################################
/* LG */
@media (min-width: 1200px) and (max-width: 1370px) {
}
@media (min-width: 992px) {
}
/* MD */
@media (min-width: 992px) and (max-width: 1199px) {
    .nav-primary .navbar-nav            { margin-top: 20px; }
    .nav-primary .navbar-nav a          { font-size: 13px; }
    header.navbar .navbar-brand                     { margin-left: -40px; background-size: 110px 26px; height: 26px; width: 110px; margin-top: 32px;}
    header.navbar.scroll .nav-primary .navbar-nav   { margin-top: 6px; }
}
/* SM - Tablet */
@media (max-width: 991px) {
    header.navbar .navbar-header                    { text-align: center; }
    .navbar-brand                                   { display: inline-block; float: none; margin-top: 25px;}
    .nav-primary .navbar-nav > li.mobile            { display: inline; }
    .utils                                          { display: none; }

    header.navbar                                   { padding: 30px 0; border: 0; }
    header.navbar .navbar-toggle                    { padding: 15px; position: absolute; z-index: 2; background: $custom-color-1; float: none; right: 0; top: 0; margin: 0; -webkit-border-radius: 0px; -moz-border-radius: 0px; border-radius: 0px; }
    header.navbar .navbar-toggle .icon-bar          { background: $custom-white; }
    header.navbar.scroll .navbar-brand              { display: none; }
    header.navbar.scroll,
    .header-bkg-post  header.navbar.scroll          { background: none; padding: 10px 0; border: 0; }

    .container > .nav-primary                       { box-shadow: none; border: 0; width: 100%; padding: 60px 0; margin: 0; float: none; max-height: none; position: absolute; top: 0; left: 0; background: $custom-color-1; overflow: hidden; }
    header.navbar .navbar-nav                       { margin: 0; }
    header.navbar .navbar-nav > li > a,
    header.navbar .utils ul li.menu-item > a,
    header.navbar.scroll .navbar-nav > li > a,
    .header-bkg-post  header.navbar .navbar-nav > li > a,
    .header-bkg-post  header.navbar .utils ul li.menu-item > a,
    .header-bkg-post  header.navbar.scroll .navbar-nav > li >  a,
    .header-bkg-post  header.navbar.scroll .utils ul li.menu-item > a        { color: $custom-white !important; text-align: center; padding-bottom: 15px; padding-top: 15px; }
    header.navbar .navbar-nav > li.active > a               { font-weight: bold; }
    header.navbar .navbar-nav > li >  a:hover,
    header.navbar .navbar-nav > li >  a:focus,
    header.navbar .navbar-nav > li >  a:hover:focus         { color: $custom-white !important; background: none; }
    .nav-primary .navbar-nav > li.open > a,
    .nav-primary .navbar-nav > li.open > a:hover,
    .nav-primary .navbar-nav > li.open > a:focus,
    .nav-primary .navbar-nav > li.open > a:hover:focus      { box-shadow: none; color: $custom-color-1 !important; background: $bkg-grey-1; }
    .navbar-nav .dropdown-menu,
    .navbar-nav .open .dropdown-menu                        { color: $custom-color-1; text-align: center; box-shadow: none; background: $bkg-grey-1; -webkit-border-radius: 0px; -moz-border-radius: 0px; border-radius: 0px; }
    .navbar-nav .dropdown-menu > li > a,
    .navbar-nav .open .dropdown-menu > li > a               { padding: 5px 13px; }
    .navbar-nav .open .dropdown-menu > li > a:hover         { background: none; }
    #mobile-info-bar { display:block;}
}
/* XS */
@media (max-width: 767px) {
    .container > .nav-primary                       { padding: 50px 0 20px 0; }
    header.navbar                                   { padding: 20px 0; }
    header.navbar .navbar-toggle                    { padding: 15px; }
    header.navbar .navbar-brand                     { margin-left: -40px; background-size: 230px 54px; height: 54px; width: 230px; margin-top: 40px;}
    header.navbar .navbar-nav > li > a,
    header.navbar .utils ul li.menu-item > a,
    header.navbar.scroll .navbar-nav > li > a,
    .header-bkg-post  header.navbar .navbar-nav > li > a,
    .header-bkg-post  header.navbar .utils ul li.menu-item > a,
    .header-bkg-post  header.navbar.scroll .navbar-nav > li >  a,
    .header-bkg-post  header.navbar.scroll .utils ul li.menu-item > a           { font-size: 15px; padding-bottom: 10px; padding-top: 10px; }
    .navbar-nav .dropdown-menu > li > a                                         { font-size: 13px; }
    #mobile-info-bar { display:block;}
}
